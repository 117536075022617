import $ from 'jquery'
$('.show-more-items-btn').click(function (event) {
	$(this).parent().parent().removeClass('hidden-articles')
	const y = $(this).parent().parent().find('.item:eq(6)').offset() //get offset of 6th element (first hidden element)
	$(this).remove()

	$('html, body').animate(
		{
			scrollTop: y.top - 100, //scroll smoothly to 6th element
		},
		500
	)
})
